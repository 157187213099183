
// Navigation Components (Links) // 
var nav_text_pt = {
  homepage:"Sobre",
  cv:"CV",
  research:"Investigação",
  publications:"Publicações",
  contacts:"Contactos"
}
var nav_text_en = {
  homepage: "About",
  cv: "CV",
  research: "Research",
  publications: "Publications",
  contacts: "Contacts"
}
export var nav_text = {
  pt: nav_text_pt,
  en: nav_text_en
}



// About/Landing Components (Texts) // 
export var about_text_pt = {
  current:[
    { title: { short: "Aluno de PhD @ UC", long: "Estudante de Doutoramento na UC"} },
    { title: { short: "Gestão @ ISCAC", long: "Estudante de Gestão no ISCAC"} }
  ],
  jobs:[
    { title: "Investigador", jobsite: "UC-ISR", href: "https://isr.uc.pt/" },
    { title: "Presidente", jobsite: "PES (Ramo UC)", href: "https://ieee-pes.org/" },
  ],
  about_me:{
    heading: "Sobre mim",
    content: [
      <span>Sou um investigador de doutoramento no campo das energias renováveis.</span>,
      <span>De momento a minha pesquisa incide sobre comunidades transactivas de energia e sou o atual presidente do ramo da <a className="font-semibold underline" href="https://ieee-uc.org/pes/">Power & Energy Society (PES) da IEEE</a><sup><i className="ri-external-link-line"></i></sup> no DEEC</span>,
    ]
  },
  current_projects: {
    heading: "Projetos atuais",
    content: [
      <span>A estudar previsão de consumos energéticos líquidos e a entrar na <span className="font-semibold">Agenda NEXUS</span><sup><i className="ri-external-link-line"></i></sup> no Porto de Sines</span>,
      <span>A aprender a gerir e construir um negócio nos meus tempos livres</span>,
      <span>Aceda à página <a className="text-accent-secondary underline font-semibold" href="/research">Investigação</a> para acompanhar a minha pesquisa!</span>, 
      <span>Pode consultar os meus artigos, etc. na página <a className="text-accent-secondary underline font-semibold" href="/publications">Publicações</a></span>
    ]
  },
}
export var about_text_en = {
  current: [
    { title: { short: "PhD Student @ UC", long: "PhD Student at UC" } },
    { title: { short: "Management @ ISCAC", long: "Management Student at ISCAC" } }
  ],
  jobs: [
    { title: "Researcher", jobsite: "UC-ISR", href: "https://isr.uc.pt/" },
    { title: "President", jobsite: "PES (UC Chapter)", href: "https://ieee-pes.org/" },
  ],
  about_me:{
    heading: "About me",
    content: [
      <span>I'm a doctorate program researcher in the fields of renewable energies.</span>,
      <span>At the moment my research focuses on transactive energy communities and I am also the current president of the <a className="font-semibold underline" href="https://ieee-uc.org/pes/">IEEE Power & Energy Society (PES)</a><sup><i className="ri-external-link-line"></i></sup> branch at DEEC</span>
    ]
  },
  current_projects: {
    heading: "Current projects",
    content: [
      <span>Working on Net Energy Demand Prediction and getting into the <a className="font-semibold underline" href="https://nexuslab.pt/">NEXUS Agenda</a><sup><i className="ri-external-link-line"></i></sup> @ Porto de Sines </span>,
      <span>Learning how to build and manage a business in my free-time </span>,

      <span>Check out my <a className="text-accent-secondary underline font-semibold" href="/research">Research</a> page for more information on what I'm working on</span>,
      
      <span>If you want to access my articles, check out my  <a className="text-accent-secondary underline font-semibold" href="/publications">Publications</a> page</span>
    ]
  },
}
export var about_text = {
  pt:about_text_pt,
  en:about_text_en,
}



// Publications Components (Texts) // 
export var publications_text_pt = {
  heading: "Publicações",
}
export var publications_text_en = {
  heading: "Publications",
}



// Contacts Components (Texts) // 
var links = [
  {name:"Google Scholar", link:""},
  {name:"IEEE", link:""},
  {name:"Linkedin", link:""},
  {name:"Facebook", link:""}
]
export var contacts_text_pt = {
  cta:"Entra em contacto comigo",
  description:"Tens alguma ideia que queiras partilhar? Queres trabalhar comigo?"

}
export var contacts_text_en = {
  cta: "Get in touch with me",
  description: "Have an idea to share? Want to work with me or discuss my work?"

}



// CV Components (Texts) // 
var cv_text_pt = {
  heading: "Curriculum Vitae",
  heading_education: "Educação",
  heading_research: "Investigação",
  heading_professional: "Experiência Profissional",
}
var cv_text_en = {
  heading: "Curriculum Vitae",
  heading_education: "Education",
  heading_research: "Research",
  heading_professional: "Professional Experience",
}
export var cv_text = {
  pt:cv_text_pt,
  en:cv_text_en,
}