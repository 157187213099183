
import { research_topics_text, research_header_generic , research_projects_text } from "../text/research-src"
import { CountupComponent } from "./Functions"
import { useState } from "react"
import { getStatsByTag } from "../text/publications-src"

import Collapsible from "react-collapsible"

export const Research = ({lang}) => {


  const ResearchBlock = ({ lang, item}) => {
    let stats = getStatsByTag(item.tag)
    return (
      <div className="animate-fade-up relative w-full md:w-[60%] h-[300px] lg:w-full lg:mx-2 lg:h-[300px] bg-white after:absolute after:top-0 after:left-0 after:rounded-xl after:w-full after:h-full after:shadow-custom2 after:opacity-[0.5] after:transition-all after:duration-300 hover:after:opacity-100 transition-all rounded-xl border-b-4 border-accent-secondary hover:border-accent cursor-pointer group">
        <div className="w-full h-full flex flex-col justify-center items-center py-6 p-3 text-center">
          
          <div className="text-5xl  text-primary group-hover:text-accent mb-2 transition-all duration-300">
            {item.icon}
          </div>
          <span className="text-xl  font-semibold text-primary transition-all duration-300">
            {item.title[lang]}
          </span>
          <span className="text-base text-primary ">
            {item.subtitle[lang]}
          </span>

          <div className="flex gap-5 my-3 ">

            <div className="flex flex-col">
              <span className="my-0 text-lg font-semibold text-accent ">
                <CountupComponent duration={1200}>
                  {stats[0]}
                </CountupComponent>
              </span>
              <span className="my-0 text-sm">{(lang == "en") ? "Articles" : "Artigos"}</span>
            </div>

            {stats[1] != 0 &&
              <div className="flex flex-col">
                <span className="my-0 text-lg font-semibold text-accent ">
                  <CountupComponent duration={1800}>
                    {stats[1]}
                  </CountupComponent>
                </span>
                <span className="my-0 text-sm">{(lang == "en") ? "Views" : "Visualizações"}</span>
              </div>
            }

          </div>

          {
            false &&
            <button className=" w-[34px] h-[34px] flex justify-center items-center bg-accent-secondary  mt-2  group-hover:opacity-100 transition-all text-white text-2xl rounded-full group-hover:bg-accent">
            <i className="ri-add-line"></i>
          </button>
          } 
        </div>

      </div>
    )
  }

  const ProjectBlock = ({lang, item}) =>{

    const ProjectBlockHeader = (props) =>{
      let headerClass;
      props.open ?
        headerClass = "relative p-4 bg-white after:shadow-custom1 after:absolute after:w-full after:h-full after:top-0 after:left-0 after:rounded-t-xl after:opacity-25 hover:after:opacity-100 after:transition-all w-full rounded-t-xl overflow-hidden group transition-all cursor-pointer"
      :
        headerClass = "relative p-4 bg-white after:shadow-custom1 after:absolute after:w-full after:h-full after:top-0 after:left-0 after:rounded-xl after:opacity-25 hover:after:opacity-100 after:transition-all w-full rounded-xl overflow-hidden group transition-all cursor-pointer"
      return(
        <div className={headerClass}>
          <div className="flex flex-row justify-between py-2 items-center">
            <div className="flex flex-row items-center">
              <div className=
              {
              "text-3xl mx-2 lg:text-4xl mr-5 lg:mr-7 " +
              (props.open?
              "bg-accent "
              :
              "bg-accent-secondary group-hover:bg-accent ")
              + "transition-all self-center min-w-[64px] min-h-[64px] flex justify-center items-center text-white rounded-full"
              }
              >
                <span className="transition-all">
                  {item.icon}
                </span>
                
              </div>
              <div className="flex flex-col">
                <h1 className={(props.open?"text-accent ":"text-primary ") + "text-xl lg:text-2xl font-semibold transition-all"}>{item.title}&nbsp;</h1>
                <h1 className="hidden lg:flex text-base lg:text-lg font-light text-secondary">{item.subtitle}</h1>
              </div>
            </div>
            
            <div className="p-3 ml-6 text-3xl">
              {
                props.open ?
                  <i className="ri-arrow-up-s-line"></i>
                  :
                  <i className="ri-arrow-down-s-line"></i>
              }
            </div>     
          </div>
        </div>
      )
    }

    const [fading,setFading] = useState(true);

    const handleFade = () =>{
      setFading(!fading)
    }

    return(
      <div className="animate-fade-left">
        <Collapsible
          trigger={<ProjectBlockHeader open={false} />}
          triggerWhenOpen={<ProjectBlockHeader open={true} />}
          transitionTime={200} easing={'ease-in-out'}
          onOpening={handleFade}
          onClosing={handleFade}
        >

          <div className="bg-white min-h-[100px] rounded-b-xl pb-8">

            <div className={(fading ? "opacity-0" : "opacity-100") + " transition-all duration-700  pt-0 px-8  flex flex-col"}>
              <div className="h-[2px] w-full lg:w-[75%] bg-grey justify-self-center mb-4"></div>

              <span className="w-[100%] lg:w-[75%] text-justify leading-snug">{item.text[lang]}</span>


              <div className="h-[2px] w-full lg:w-[75%] bg-grey justify-self-center my-4"></div>
              {item.links && <span className="">
                {lang == "en" ? "Relevant Links:" : "Links Relevantes"}
              </span>}
              <div className=" mt-2 text-sm flex flex-col lg:flex-row gap-3">
                {item.links &&
                  <>

                    {item.links.map((each, i) =>
                      <a className='flex cursor-pointer p-2 px-4 bg-accent-secondary hover:bg-accent transition-all w-[150px] rounded-lg text-grey  hover:text-white' key={i} href={each.href}>
                        {each.title}&nbsp;<i className="ri-external-link-line p-0 m-0 text-xs"></i>
                      </a>
                    )}
                  </>

                }
              </div>
            </div>

          </div>
        </Collapsible>
      </div>
      

    )
  }

  const ResearchHeadingText = ({lang}) =>{
    return(
    <div className="animate-fade-down mt-12 mb-12 text-lg w-[90%] lg:w-[75%] xl:w-[50%] self-center text-center flex flex-col leading-relaxed">
      {research_header_generic[lang]}
    </div>
    )
  }

  return (
    <section className='bg-gradient-to-b from-white to-grey py-0  lg:py-3 mb-8 pb-8 xl:pb-6 lg:pt-12 flex-grow' >
      <div className='container mx-auto flex flex-col items-start p-6 '>

        {/* BEGIN Header */}
        <div className='mb-0 lg:mb-1 flex flex-row  w-full justify-between md:h-[90px]'>
          <div className=' w-fit h-full flex items-end'>
            <h1 className="text-3xl md:text-4xl font-semibold font-headings">
              {(lang == "en") ? "Research Topics" : "Tópicos de Investigação"}
            </h1>
          </div>
          <div className='gridCellsLight hidden md:flex w-[51%] xl:w-full '>
          </div>
        </div>
        {/* END Header */}

        

        {/* BEGIN Grid*/}
        <div className="flex flex-col lg:flex-row w-full items-center justify-center lg:justify-between gap-6 lg:gap-2 mt-12 mb-6">
          {
            research_topics_text.map((each, index) => 
              <ResearchBlock lang={lang} item={each} key={index}/>
              
            )
          } 
        </div>
        {/* END Grid */}

        <ResearchHeadingText lang={lang}/>

        <div className='gridCells h-[50px] my-6 md:flex w-full xl:w-[67%] self-center' />

        {/* BEGIN Grid*/}
        <div className="flex flex-col w-full mt-12 gap-6">
          <h1 className="text-3xl md:text-4xl font-semibold mb-8 font-headings">
            {(lang == "en") ? "Current Projects" : "Projetos Atuais"}
          </h1>
          {
            research_projects_text.map((each, index) => 
                <ProjectBlock lang={lang} item={each} key={index}/>
              
            
            )
          }
        </div>
        {/* END Grid */}

        <div className='gridCells mt-12 hidden h-[100px] lg:h-[300px] md:flex w-[51%] xl:w-full '>
        </div>

      </div>
    </section>
  )
}