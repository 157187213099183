









var KeywordFederatedLearning = {
  title: { en: "Federated Learning", pt:"Federated Learning "},
  subtitle: { en: "in energy management systems", pt:"na gestão de sistemas de energia"},
  icon: <i className="ri-wireless-charging-line"></i>,
  tag:"SG",
  keyword:"Federated Learning",
  content:{
  }
}  
var KeywordNeuralNetworks = {
  title: { en: "Neural Networks", pt: "Redes Neuronais" },
  subtitle: { en: "in energy management systems", pt: "na gestão de sistemas de energia" },
  icon: <i className="ri-brain-line"></i>,
  tag: "FL",
  keyword: "Artificial Neural Networks",
  content: {
  }
}
var KeywordNetDemand = {
  title: { en: "Net Energy Demand Forecast", pt: "Predição de Consumos Energéticos" },
  subtitle: { en: "through predictive algorithms", pt: "via algoritmos preditivos" },
  icon: <i className="ri-line-chart-line"></i>,
  tag: "TS",
  keyword:"Net Energy Demand Forecast",
  content: {
  }
}





export const research_header_generic = {
  en:"My research is centered around the energy market and how it may be possible to optimize energy distribution and energy grid management in the age of renewable energy.",
  pt:"A minha pesquisa centra-se no mercado de energia, e nas oportunidades que existem de otimizar a distribuição de energia e a gestão das redes elétricas na era das energias renováveis."
}

export const research_topics_text = [
  KeywordFederatedLearning,
  KeywordNeuralNetworks,
  KeywordNetDemand,
]



var mlgridedge = {
  icon: <i className="ri-shape-fill"></i> ,
  title:"ML@ GridEdge",
  subtitle:"Distributed Machine Learning Solutions for Coordinating Distributed Energy Resources at the Edge of the Power Grid",
  text: {
    en: "ML@GridEdge was a project tackling challenges in the electric grid. It aims to predict energy needs in connected communities using a privacy-friendly machine learning model, contributing to the shift towards cleaner energy.The project's outcomes include improved energy predictions, software applications, and sharing knowledge through academic channels and student (MSc and PhD) training.",
    pt: "O projeto ML@GridEdge enfrentou desafios na rede elétrica em prever as necessidades de energia em comunidades conectadas usando um novo modelo de ML (Machine Learning). Um dos focos principais é na privacidade dos dados das entidades abrangidas, integrando energias renováveis para melhorar as previsões e contribuir para uma energia mais limpa. Os resultados esperados incluem soluções de previsão de energia, modelos, aplicações e partilha de conhecimento académico, além de formação de estudantes de mestrado e doutoramento."
  },
  links: [
    { title: "ISR Projects", url:"https://www.isr.uc.pt/index.php/projects/past-projects"},
    { title: "ML@GE @AU", url:"https://utaustinportugal.org/mlgridedge-distributed-machine-learning-solutions-for-coordinating-distributed-energy-resources-at-the-edge-of-the-power-grid/"}
  ]
}
var collecte = {
  icon: <i className="ri-p2p-fill"></i>,
  title:"ColLeCTE",
  subtitle:"Collaborative Learning for the Coordination of Transactive Energy systems",
  text: {
    en: "The main objectives of this project are to design a federated learning framework to ensure the prediction of prosumption at the building level in transactive energy systems. The objective is to use data available in each building while ensuring its privacy concerns. Therefore, another objective is to define the data and communication infrastructure required for the implementation of the learning framework.",
    pt: "O principal objetivo deste projeto é desenhar uma framework de federated learning (FL) para garantir a previsão de prosumo ao nível do edifício em sistemas transativos de energia. O objetivo é utilizar os dados disponíveis em cada edifício, garantindo simultaneamente as preocupações com a privacidade. Portanto, outro objetivo é definir a infraestrutura de dados e comunicação necessária para a implementação do framework de aprendizagem federada."
  },
}
var nexus = {
  icon: <i className="ri-global-fill"></i>,
  title:"NEXUS Agenda",
  subtitle:"Green strategy for Seaport and Logistics Network",
  text: {
    en: "NEXUS Agenda will create an ecosystem of 28 products and services for the Green and Digital Transition in the transport and multimodal sectors. At the centre of this ecosystem, the core product, is the NEXUS OPEN DATA COLLABORATION PLATFORM, comprising a set of applications and services (NEXUS FEDERATED APPLICATIONS AND SERVICES). Finally, the NEXUS HARWARE ASSETS component, of innovative hardware products that exploit IoT principles and promote important synergies between the collaborative open data platform and the other applications and services. Each of these will have its digital representation (digital twin), aimed at sharing and receiving data, intended to improve the performance of operations.",
    pt: "A Agenda NEXUS criará um ecossistema de 28 produtos e serviços para a Transição Verde e Digital nos setores dos transportes e multimodal. No centro deste ecossistema, o produto core, está o NEXUS OPEN DATA COLLABORATION PLATFORM, compreendendo um conjunto de aplicações e serviços (NEXUS FEDERATED APPLICATIONS AND SERVICES). Por fim, a componente NEXUS HARWARE ASSETS, de produtos de hardware inovadores que exploram princípios de IoT e promovem importantes sinergias entre a plataforma colaborativa de dados abertos e as demais aplicações e serviços. Cada um destes terá a sua representação digital (digital twin), visando a partilha e receção de dados, destinados a melhorar a performance das operações."
  },
  links: [
    { title: "NEXUS @UA", url: "https://www.ua.pt/en/agendasprr/NEXUS" },
    { title: "NEXUS Agenda", url:"https://nexuslab.pt/"}
  ]
}
var lifereverter = {
  icon: <i className="ri-team-fill"></i>,
  title:"LIFE-REVERTER",
  subtitle:"Deep REnovation roadmaps to decrease households VulnERability to Energy poveRty",
  text: {
    en: "REVERTER Hub is aimed at all EU citizens landlords and tenants of uninsulated buildings, construction and renovation companies, policy makers and other stakeholders. Through REVERTER Hub, the project REVERTER consortium partners share the project results as well as access to other useful tools and resources with one aim to reduce the energy poverty in Europe and improve the quality of life of EU citizens.",
    pt: "O projeto REVERTER pretende agilizar a redução da pobreza energética na Europa integrando a educação, economia, ambiente, sociedade civil e governança na melhoria eficaz de edifícios e vizinhanças.  Partindo de uma avaliação integrada e da co-criação com os diversos interlocutores, o projeto REVERTER irá desenvolver 9 roteiros a validar em 4 países: Bulgária, Grécia, Letónia e Portugal. Na reunião de arranque do projeto em Atenas, no início de Novembro, a estratégia de implementação do projeto foi ajustada para responder ao crescente risco de pobreza energética decorrente da crescente crise socioeconómica na Europa."
  },
  links: [
    { title: "ReverterHub", url: "https://reverterhub.eu/"},
    { title: "Coimbra.pt", url:"https://renovar.coimbra.pt/projeto-reverter-financiado-pelo-programa-life-da-eu/"}

  ]
}

export const research_projects_text = [
  mlgridedge,
  collecte,
  nexus, 
]