import { useState } from 'react'
import {contacts_text_en, contacts_text_pt} from '../text/text-generic-src'

export const Contacts = ( {lang} ) => {
  const [text, setText] = useState();
  
  function handleChange(e) {
    setText(e.target.value);
  }

  const input_css = "bg-grey rounded-t-md border-b-4 border-accent-dark p-2"
  const textarea_css = input_css + " h-full resize-none";

  return (
    <section className='bg-grey py-0 lg:py-3 mb-8 pb-8 xl:pb-6 flex-grow flex justify-center items-center' >
      <div className='container mx-auto flex flex-col items-start gap-5 p-6'>
        
      

        <div className="flex flex-col lg:flex-row w-full lg:w-[75%] bg-white rounded-2xl shadow-custom2 gap-12 overflow-hidden justify-between self-center justify-self-center">

          <div className="box-border w-full lg:w-[65%] p-8 flex flex-col items-center">
            <p className="text-lg text-primary md:text-2xl mb-8">
              {lang == "en" ? contacts_text_en.description : contacts_text_pt.description}
            </p>

            
            <form className='flex text-sm flex-col w-full gap-5'>
              <label className='flex flex-col w-full lg:flex-row justify-start gap-5 '>
                <div className='flex flex-col'>
                  <div className='text-secondary mb-1'>Name <span className='text-red-400'>(*)</span></div>
                  <input className={input_css} type="text" name="name" required />
                </div>
                <div className='flex flex-col flex-grow'>
                  <div className='text-secondary  mb-1'>e-Mail <span className='text-red-400'>(*)</span></div>
                  <input className={input_css} type="email" name="email" required />
                </div>
              </label>
              <label className='flex flex-col'>
                <div className='text-secondary  mb-1'>Subject <span className='text-red-400'>(*)</span></div>
                <input className={input_css} type="text" name="Subject" required />
              </label>
              <label className='flex flex-col flex-grow h-[250px] lg:h-[150px]'>
                <div className='text-secondary  mb-1'>Message <span className='text-red-400'>(*)</span></div>
                <textarea className={textarea_css} value={text} onChange={handleChange} required />
              </label>

              <input className='btn w-fit self-center bg-accent-dark hover:bg-accent-secondary transition-all p-2 px-12 text-white rounded-md mt-2' type="submit" value="Submit" />

            </form>


          </div>

          <div className="box-border w-[35%] relative select-none hidden lg:flex">
            <img className="grayscale brightness-75 h-full object-cover" src="https://thispersondoesnotexist.com/" />
            <p className="top-[50%] font-headings left-5 absolute text-4xl text-white drop-shadow-xl ">
              {lang=="en"?contacts_text_en.cta:contacts_text_pt.cta}
            </p>
          </div>

        </div>



      </div>
    </section>
  )
}