
import { publications_text_en, publications_text_pt } from '../text/text-generic-src';
import { publications_items } from '../text/publications-src';
import Collapsible from 'react-collapsible';
import { useState, useEffect, Component, Fragment } from 'react';


function truncate(str, n) {
  return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
};


class AbstractSection extends Component {
  constructor( props ){
    super(props);  
    this.state = { extended: false }  
    this.handleExtension = this.handleExtension.bind(this);
  }
  handleExtension(value) {
    this.setState({
      extended: value
    });
  }
  render(){
    return(
      <Collapsible
        trigger={
          <div className="border-b flex py-3 justify-between gap-2 items-center">
            <div className='text-primary transition-all text-base lg:text-sm'>
              <i className="ri-quote-text mr-1"></i>{this.props.header}
            </div>
            <div> 
              <i className='ri-arrow-down-s-line'></i> 
            </div>
          </div>
        }
        triggerWhenOpen={
          <div className="border-b flex py-3 justify-between gap-2 items-center">
            <div className='text-accent-secondary transition-all text-base lg:text-sm'>
              <i className="ri-quote-text mr-1"></i>{this.props.header}
            </div>
            <div> 
              <i className='ri-arrow-up-s-line'></i> 
            </div>
          </div>
        }
        onClose={()=>this.handleExtension(false)} transitionTime={200} easing={'ease-in-out'}
      >
        {/* --------------BEGIN BODY------------------- */}
        <div className='w-full p-3 py-4 lg:p-4 text-base lg:text-sm bg-grey text-secondary'>
          {this.state.extended == false ?
          <>
              <span className='hidden lg:flex'>{truncate(this.props.contents, 450)}</span>
              <span className='flex lg:hidden'>{truncate(this.props.contents, 250)}</span>
              <button className=' text-primary' onClick={() => this.handleExtension(true)}>
                [<span className='underline'>{this.props.lang == "en" ? "See More" : "Ver Mais"}</span>]
              </button>
          </>
          :
          <>
              {this.props.contents}
              <button className=' mx-1 text-primary' onClick={() => this.handleExtension(false)}>
                - [<span className='underline'>{this.props.lang == "en" ? "See Less" : "Ver Menos"}</span>]
              </button>
          </>}
        </div>
        {/* --------------END BODY------------------- */}
      </Collapsible>
    )
  }
}
class CitationSection extends Component {
  constructor( props ){
    super(props);
  }
  render(){
    return(
      <Collapsible
        trigger={
          <div className="border-b flex py-3 justify-between gap-2 items-center">
            <div className='text-primary transition-all text-base lg:text-sm'>
              <i className="ri-double-quotes-r mr-2"></i>{this.props.header}
            </div>
            <div>
              <i className='ri-arrow-down-s-line'></i>
            </div>
          </div>
        }
        triggerWhenOpen={
          <div className="border-b flex py-3 justify-between gap-2 items-center">
            <div className='text-accent-secondary transition-all text-base lg:text-sm'>
              <i className="ri-double-quotes-r mr-2"></i>{this.props.header}
            </div>
            <div>
              <i className='ri-arrow-up-s-line'></i>
            </div>
          </div>
        }
        transitionTime={200} easing={'ease-in-out'}
      >
        {/* --------------BEGIN BODY------------------- */}
        <div className='w-full p-3 py-4 lg:p-4 text-base flex flex-row flex-wrap gap-2 lg:text-sm bg-grey text-primary'>
          {
            this.props.contents.map((each, index)=>
       
                <Citation lang={this.props.lang} name={each.citation_name} contents={each.citation_content} key={index}/>

            )
          }
        </div>
        
        {/* --------------END BODY------------------- */}
      </Collapsible>
    )
  }
}
const Citation = ({lang, name, contents}) =>{

  const [isCopied, setIsCopied] = useState(false);
  const [fade, setFade] = useState(false);


  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleFade = () => {
    setTimeout(
      () => {
        setIsCopied(false);
        setFade(false);
      }, 1500);
   }

  const handleCopyClick = () => {
    if (isCopied) return;
    // Asynchronously call copyTextToClipboard
    setFade(false);
    copyTextToClipboard(contents)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(
          () => {
            setFade(true);
            handleFade();
          }, 1500);
        })
      .catch((err) => {
        console.log(err);
      });
  }

  var className = "text-base h-[75px] rounded-lg bg-white p-4 px-8 flex justify-center items-center border-[1px] border-grey transition-all duration-[1500ms] shadow-custom2";

  return(
    <div>
      <div 
        className="cursor-pointer bg-white flex text-secondary hover:text-primary justify-center rounded-md p-2 px-4"
        onClick={() => {handleCopyClick(contents)}}>
        {name}
      </div>
      {
      isCopied && 
        <div className="animate-fade-left z-[101] fixed w-full flex justify-center items-center bottom-8 left-0">
          <div className=
              {fade ? className + " opacity-0" : className + " opacity-100"}
          >
            {lang == "en" ?
              "Copied to clipboard (" + name + ")"
              :
              "Copiado para a área de cópia (" + name + ")"
            }
          </div>
        </div>
      }
    </div>
    
  )
}

export const Publications = ({lang}) => {
  
  const [sortType, setSortType] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortedPublications, setSortedPublications] = useState([]);

  useEffect(() => {
    // Function to compare values based on sortType and sortOrder
    const compareFunction = (a, b) => {
      const aValue = a[sortType];
      const bValue = b[sortType];

      // Handle different data types if necessary
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
      }
    };

    // Sort the publications array based on the selected criteria
    const sortedArray = [...publications_items].sort(compareFunction);
    setSortedPublications(sortedArray);
  }, [publications_items, sortType, sortOrder]);


  const PublicationBlock = (props) =>{


    return(
      <div className={'animate-fade-left w-full bg-white  rounded-lg shadow-custom1 p-4 lg:p-8 lg:py-4'}>

        <div className='flex flex-col lg:flex-row justify-between'>
          {
          props.links
          ? 
            <a href={props.links[0].link_href} className='text-lg text-accent-dark hover:text-accent-secondary lg:w-[75%] font-semibold transition-all'>
                {props.title}&nbsp;<sup><i className="ri-external-link-line"></i></sup>
            </a>
          : 
              <a className='text-lg text-primary font-semibold transition-all'>
              {props.title}
            </a>
          }
          <p className='text-base'>{props.date}</p>
        </div>
        <hr className='my-2'></hr>
        <p className='text-base text-primary'>{props.authors}</p>
        <p className='text-sm'>{props.conference}</p>
        <div className='hidden lg:flex items-center mt-2 gap-2'>
          <span className='text-sm text-primary font-semibold '>Keywords:</span>
          {props.keywords.slice(1, 4).map((each,index)=>{
            return(
              <div className=" p-1 px-2 text-secondary capitalize rounded-md border bg-grey bg-opacity-50 self-center text-sm" key={index}>
                {each}
              </div>
            )
          })}
        </div>
        <div className='text-sm mt-4 flex flex-col justify-between items-start'>
          {
            props.links
            ?
              <div className=' mb-2 flex flex-col gap-y-3 gap-3 lg:flex-row'>
              {
                 props.links.map((each, i, arr) =>
                   <div className='flex p-2 px-4 bg-accent-secondary hover:bg-accent transition-all rounded-lg group ' key={i}>
                    <a 
                      className="text-grey group-hover:text-white" 
                      href={each.link_href}
                    >
                       {each.link_name}&nbsp;<i className="ri-external-link-line p-0 m-0 text-xs"></i>
                    </a>
                    <span className='mx-2 select-none text-primary hidden'>
                      {i != (arr.length - 1) ? '|' : ''}
                    </span>
                  </div>
                )
              }
              </div>
            :
            <></>
          }
          <div className=' w-full'>
            {
              props.abstract
              ?
              <AbstractSection 
                header={<>{(lang == "en") ? " Abstract" : " Resumo"}</>} 
                contents={props.abstract}
                lang={lang}
              />
              :
              <></>
            }
            {
              props.citations
              ?
                <CitationSection
                  header={<>{(lang == "en") ? " Citations" : " Citações"}</>} 
                  contents={props.citations}
                  lang={lang} 
                />
              :
              <></>
            }
          </div>
        </div>
      </div>
    )

  }

  const handleSortTypeChange = (event) => {
    setSortType(event.target.value);
  };

  const swapOrder = () => {
    if (sortOrder=="asc"){
      setSortOrder("desc")
    }else{
      setSortOrder("asc")
    }
  }

  return (
    <section className = 'bg-gradient-to-b from-white to-grey py-0  lg:py-3 mb-8 pb-8 xl:pb-6 lg:pt-12 flex-grow' >
      <div className='container mx-auto flex flex-col items-start gap-5 p-6 '>


        <div className='mb-0 lg:mb-3 flex flex-row  w-full justify-between md:h-[90px]'>
          
          <div className=' w-fit h-full'>
            <h1 className="text-3xl md:text-4xl font-semibold mb-4 font-headings">
             {(lang == "en") ? publications_text_en.heading : publications_text_pt.heading}
            </h1>

            <div className='text-primary flex gap-2 flex-row items-start justify-start md:items-center'>
              <div className='flex gap-2'>
                <i className="pt-[2px] text-lg ri-arrow-up-down-fill"></i>
                <p className='hidden md:text-md md:flex'>{(lang == "en") ? " Sort" : " Ordenar"}</p>
              </div>
              <select value={sortType} onChange={handleSortTypeChange} className='text-base bg-grey rounded-md px-1 md:px-4 py-1 outline-none' id="sortSelect">

                <option className="p-2" value="date">
                  {(lang == "en") ? 
                  " Date" 
                  : 
                  " Data"
                  }
                </option>

                <option className="p-2" value="title">
                  {(lang == "en") ? 
                  " Alphabetical" 
                  : 
                  " Alfabética"
                  }
                </option>

              </select>


              <div className='w-[36px] h-[30px] bg-grey rounded-md cursor-pointer text-primary hover:text-accent transition-all flex duration-300 justify-center items-center' onClick={swapOrder}>

                {sortType==="date"?
                    sortOrder==="asc"?
                    <i className="text-lg ri-sort-asc"></i>
                    :
                    <i className="text-lg ri-sort-desc"></i>
                  :
                    sortOrder === "asc" ?
                    <i className="text-lg ri-sort-alphabet-asc"></i>
                    :
                    <i className="text-lg ri-sort-alphabet-desc"></i>
                }


              </div>


            </div>
          </div>

          <div className='gridCellsLight hidden md:flex w-[51%] xl:w-[67%] '>
          </div>

        </div>
        
        

        {
          sortedPublications.map((each,index)=>{
            return(
              <PublicationBlock

                title={each.title}
                authors={each.authors}
                abstract={each.abstract}
                mainlink={each.mainlink}
                conference={each.conference}
                date={each.date}
                links={each.links}
                keywords ={each.keywords}
                citations={each.citations}
                key={index}
              />
            )
          }
            
        )
        }

        <div className='gridCells hidden h-[100px] my-6 mt-8 md:flex w-full xl:w-[95%] self-center' />
      </div>

      
    </section>
  )
}