import { Routes, Route } from "react-router-dom";
import { useState, useEffect} from "react";
import { Navigation, Footer } from "./components/Nav";
import 'remixicon/fonts/remixicon.css'
import { About } from "./components/About";
import { Publications } from "./components/Publications";
import { Contacts } from "./components/Contacts";
import { Research } from "./components/Research";
import { CV } from "./components/CV";

const App = () => {
  
  const [lang, setLanguage] = useState("pt");
  const setLang = (lang) =>{
    localStorage.setItem("lang", lang);
    // setting state to false to not display pop-up
    setLanguage(lang);
  }
  useEffect(() => {
    let returningUser = localStorage.getItem("lang");
    
    if (returningUser == undefined)
    {returningUser='pt'}
    
    setLanguage(returningUser);
  }, []);
  
    return (
      <div className="flex flex-col min-h-screen">
        <Navigation lang={lang} languageChangeHandler={setLang}/>

        <Routes>

          <Route
            path="/"
            element=
            { // Home/Landing page
              <About lang={lang}/>
            }
          />

          <Route
            path="/cv"
            element=
            { // Curriculum Vitae page
              <CV lang={lang} />
            }
          />

          <Route
            path="/research"
            element=
            { // Research Areas page
              <Research lang={lang}/>
            }
          />

          <Route
            path="/publications"
            element=
            { // Publications page
              <Publications lang={lang}/>
            }
          />

          <Route
            path="/contact"
            element=
            { // Contacts and info page
              <Contacts lang={lang}/>
            }
          />

        </Routes>

        <Footer lang={lang} />
      </div>

      

    );
  
  
}

export default App;
