import { cv_text } from "../text/text-generic-src"
import { cv_contents } from "../text/cv-src"
import Collapsible from 'react-collapsible';
import { useState, useEffect } from "react";
import splash_panel from './../assets/splash_panel.webp'

var durationLabel = {en:"ongoing",pt:"atual"};


export const CV = ({ lang }) => {
  const [sortedCVItems, setSortedCVItems] = useState(cv_contents.cv_timeline_items);

  // Filter State
  const [filterType, setFilterType] = useState("default")
  const [listFadeSignal, setListFadeSignal] = useState(false)

  // Slide State
  const [currentSlide, setCurrentSlide] = useState(null)

  // Selection State
  const [selectedItem, setSelectedItem] = useState(null)
  const getHighlightItem = (array) =>{
    var result = null;
    array.some(
      item => {
        if (item['auto_open'] === true) {
          result = item;
          return true;
        }
        return false;
      }
    )
    return result;
  }
  // Mobile state for swapping CV Views
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);


  // Filter Type swapping effect (re-filters list)
  useEffect(() => {
    const sortedArray = [...cv_contents.cv_timeline_items].filter(
      item=>
        // If in category
        item.category.includes(filterType) || 
        // If category is highlights and size is above "lg"
        (filterType==="highlight" && (item.size==="lg" || item.size==="xl")) ||
        // If category is default
        filterType==="default"
      );

    setSortedCVItems(sortedArray);
  },[cv_contents.cv_timeline_items, filterType]);

  // Effect for swapping over to mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Effect for pre-selecting highlight item
  useEffect(() => {
    // Find the first item with "auto-select" set to true
    
    var autoSelectItem = getHighlightItem(sortedCVItems);
    if (!autoSelectItem){
      autoSelectItem = sortedCVItems[0];
    }
    setSelectedItem(autoSelectItem)
    if (autoSelectItem) {
      handleSlideChange(autoSelectItem, 
        {
          image: autoSelectItem.image,
          title:autoSelectItem.title,
          year: autoSelectItem.year,
          duration:autoSelectItem.duration,
          desc: autoSelectItem.desc,
          links: autoSelectItem.links
        }
        )
      
    }
  }, [sortedCVItems]); 

  // Handler for changing filter type
  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value)
  }

  // Handler for swapping slides on the desktop view
  const handleSlideChange = (selected, slide) => {
    if (currentSlide===slide){
      return
    }else{

      setCurrentSlide(slide)
      setSelectedItem(selected)
    }
  }

  const handleMobileSelectChange = (selected) =>{
    if (selected === selectedItem){
      setSelectedItem(null)
    }
    else{
    setSelectedItem(selected)  
    }

  }


  const CVSlide = ({ image, title, duration, year, desc, links }) => {

    const [fadeIn, setFadeIn] = useState(false)

    useEffect(() => {
      setFadeIn(true);
    });


    return (
      <div className={(fadeIn ? "animate-fade animate-duration-1000 " : "") + " relative w-full h-full flex justify-center transition-all"}>
        <img className="absolute w-full h-full object-cover  brightness-75 z-10 select-none" src={image ? image : splash_panel} />
        <div className="absolute z-20 backdrop-blur-md bg-gray-800 bg-opacity-70 flex flex-col p-4 xl:p-8 justify-start self-end text-lg text-white border-[1px]  border-[rgba(255,255,255,0.1)] m-3 w-[calc(100%-2rem)]">
          <span className="text-md mb-2 flex flex-col">

            <span className="font-semibold">{title}</span>
            <span className="text-base text-gray-400" >
              {duration ? "(" + duration + ")" : year}
            </span>

          </span>
          <span className="text-base mb-2">

            {desc ? desc : <></>}

          </span>

          <span className="flex flex-col gap-1 xl:flex-row xl:gap-0">
            {links ?
              links.map((each, index) => {
                return (
                  <div className="text-sm xl:pt-4 flex" key={index}>
                    <a href={each.link} className=" text-accent hover:text-accent-tertiary transition-all">
                      <span className="underline underline-offset-[3px]">{each.title}</span>
                      <sup><i className="ri-external-link-fill text-accent"></i></sup>
                    </a>
                    <div className="hidden xl:block">{index != links.length - 1 && <span className="px-2">|</span>}</div>
                  </div>
                )
              })
              :
              <></>
            }

          </span>
        </div>
      </div>
    )
  }



  return (
    <section className='bg-gradient-to-b from-white to-grey py-0 lg:py-3 mb-8 pb-8 xl:pb-6 lg:pt-12 flex-grow' >
      <div className='container mx-auto flex flex-col items-start gap-5 p-6 lg:p-6 '>

        {/* BEGIN Header */}
        <div className='mb-0 lg:mb-3 flex flex-row  w-full justify-between md:h-[90px] '>
          
          <div className=' w-fit h-full'>
            <h1 className="text-3xl md:text-4xl font-semibold mb-4 font-headings">
             {cv_text[lang].heading}
            </h1>
            <div className='text-primary flex gap-2 flex-row items-start justify-start md:items-center'>
              <div className='flex gap-2'>
                <i className="ri-filter-2-fill  mb-[2px]"></i>
                <p className='mb-[2px] hidden md:text-md md:flex'>{(lang == "en") ? " View" : " Visualisar"}</p>
              </div>
              <select value={filterType} onChange={handleFilterTypeChange} className='text-base bg-grey rounded-md px-1 md:px-4 py-1 outline-none w-full' id="filterSelect">
                <option className="p-2" value="default">{(lang == "en") ? " Timeline (All)" : " Cronologia (Todos)"}</option>
                <option className="p-2" value="highlight">{(lang == "en") ? "  Highlights" : " Destaques"}</option>
                <option className="p-2" value="education">{(lang == "en") ? " Education" : " Educação"}</option>
                <option className="p-2" value="extracurricular">Extracurricular</option>
              </select>
            </div>
          </div>
          <div className='gridCellsLight hidden md:flex w-[29.5%] lg:w-[59.5%] xl:w-[67%] '>
          </div>
        </div>
        {/* END Header */}

        {/* BEGIN CV View */}
        <div className="flex flex-row w-full justify-between">

          
          {/* BEGIN Timeline View */}
          <div className={(listFadeSignal ? "animate-fade-left " : " ") +" w-full lg:w-[50%] h-full " + (isMobile&&" rounded-r-xl")}>
            {
              !isMobile
                ?
                <div className=" relative border-accent-dark border-r-4 ml-[14px] border-t-4 h-[30px] w-[50px] rounded-tr-2xl">
                  <div className="w-[16px] h-[16px] bg-accent-dark rounded-full absolute top-[-9px] left-[-9px]"/>
                </div>
                :
                <div className="relative border-accent-dark border-t-4 border-l-4 h-[30px] w-[0px]">
                  <div className="w-[16px] h-[16px] bg-accent-dark rounded-full absolute top-[-10px] left-[-10px]" />
                </div>
            }

            {
              sortedCVItems.map((each,index)=>{
              return(
                !isMobile
                ?
                // Desktop View
                <div key={index} 
                  className={   
                    (selectedItem === each ? " ":"")
                    + " h-[120px] cursor-pointer  w-full flex items-center py-0 transition-all duration-300"} 
                  onClick={() => 
                    handleSlideChange(each,{
                        image:each.image,  
                        title:each.title,
                        year:each.year,
                        duration:each.duration,
                        desc:each.desc, 
                        links:each.links
                      })
                  }
                  >
                    <div className={(selectedItem===each ? "bg-white shadow-custom2 ":" ")+" w-[60px] text-accent-secondary h-full flex justify-center items-center transition-all rounded-l-xl"}>
                      <span className={(selectedItem === each ? "text-accent-secondary " : " text-secondary opacity-[0.6] ")+" transition-all text-sm font-semibold"}>{
                      each.year
                      }</span>
                    </div>
                    <div className={(selectedItem === each ? "bg-white pl-10 shadow-custom2 border-accent " : " border-accent-dark pr-5 pl-5 ") +" relative w-[75%] border-l-4  h-full flex flex-col items-start justify-center transition-all rounded-r-xl"}>
                    {
                      selectedItem===each &&
                      <div className="animate-fade-right animate-duration-150 absolute left-[7px] text-accent">
                        <i className="ri-arrow-right-s-line text-3xl"></i>
                      </div>
                    }
                    <span className={
                        (selectedItem === each ? "text-accent-secondary  " : " text-primary ")
                        + "text-base lg:text-md transition-all font-semibold w-full"}
                      >{each.title[lang]}</span>
                    <span className={
                      (selectedItem === each ? "  " : "  ")
                      + "text-xs lg:text-sm"}>
                      {
                        each.interval ? "(" + each.interval.start + " - " + (each.interval.end?each.interval.end:durationLabel[lang]) + ")" :
                        each.duration ? "("+each.duration[lang]+")" : ""
                      }
                    </span>
                  </div>
                </div>
                :
                // Mobile View
                  <div key={index} onClick={() => handleMobileSelectChange(each)} className={selectedItem === each ?"shadow-custom2 rounded-r-xl":""}>
                    <Collapsible
                        transitionTime={200} easing={'ease-in-out'}
                        trigger=
                        {
                          <div className="relative  md:h-[100px] cursor-pointer  flex items-center py-0 px-0 transition-all duration-300">
                            <div className="border-l-4 py-3 pt-4 h-full flex flex-col items-start justify-center border-accent-dark pl-2 transition-all pr-8">

                              <span className="text-sm font-semibold text-secondary opacity-[0.6]"> {each.year}</span>

                              <span className="text-base font-semibold lg:text-md text-primary">{each.title[lang]}</span>
                              <span className="text-xs lg:text-sm">
                                {
                                  each.interval ? "(" + each.interval.start + " - " + (each.interval.end ? each.interval.end : durationLabel[lang]) + ")" :
                                    each.duration ? "(" + each.duration[lang] + ")" : ""
                                }
                              </span>
                            </div>
                          </div>
                        }
                        open={selectedItem===each}
                        triggerWhenOpen=
                        {
                          <div className="bg-white rounded-tr-xl relative  md:h-[100px] cursor-pointer  flex items-center py-0 px-0 transition-all duration-300">
                            <div className="border-l-4 py-3 pt-4 h-full flex flex-col items-start justify-center border-accent pl-8 transition-all">

                              <span className="text-sm font-semibold text-accent-secondary"> {each.year}</span>

                              <span className="text-base lg:text-md font-semibold text-accent-secondary">{each.title[lang]}</span>
                              <span className="text-xs lg:text-sm">
                                {
                                  each.interval ? "(" + each.interval.start + " - " + (each.interval.end ? each.interval.end : durationLabel[lang]) + ")" :
                                    each.duration ? "(" + each.duration[lang] + ")" : ""
                                }
                              </span>
                            </div>
                            {
                              selectedItem === each &&
                              <div className="animate-fade-right animate-duration-150 absolute left-[7px] text-accent">
                                <i className="ri-arrow-right-s-line text-3xl"></i>
                              </div>
                            }
                          </div>
                        }
                      
                      >

                        <div className="bg-white bg-opacity-[0.6] text-primary rounded-br-xl leading-snug p-4 pb-12 border-l-4 border-accent">
                          <span className="mb-3 text-sm">{each.desc[lang]}</span>
                          <div className="mt-3 flex flex-col gap-3">
                            {
                              each.links ?
                                each.links.map((each, index) => {
                                  return (
                                    <div className="text-sm flex" key={index}>
                                      <a href={each.link} className=" text-accent-secondary hover:text-accent transition-all">
                                        <span className="underline underline-offset-[3px]">{each.title}</span>
                                        <sup><i className="ri-external-link-fill text-accent"></i></sup>
                                      </a>
                                    </div>
                                  )
                                })
                                :
                                <></>
                            }
                          </div>

                        </div>

                    </Collapsible>
                </div>
                

                )
              })
            }
          {
            !isMobile
            ?
            <div className="relative ml-[60px] border-accent-dark border-l-4 border-b-4 h-[30px] w-[120px] rounded-bl-2xl">
              <div className="w-[16px] h-[16px] bg-accent-dark rounded-full absolute bottom-[-9px] right-[-9px]" />
            </div>
            :
            <div className="relative border-accent-dark border-b-4 border-l-4 h-[30px] w-[150px] rounded-bl-2xl">
              <div className="w-[16px] h-[16px] bg-accent-dark rounded-full absolute bottom-[-10px] left-[140px]" />
            </div>
          }

          </div>
          {/* END Timeline View */}

          {/* BEGIN Slide View */}
          {!isMobile && 
            <div className={(listFadeSignal ? "  " : " ") + "  relative hidden lg:flex w-full lg:w-[55%] flex-grow  "}>
              {
                currentSlide &&
                <>
                  <div className={"bg-primary flex w-full h-[400px] xl:h-[500px] sticky top-[150px] z-20 overflow-hidden rounded-xl shadow-custom1"}>
                    <CVSlide
                      image={currentSlide.image}
                      desc={currentSlide.desc[lang]}
                      title={currentSlide.title[lang]}
                      duration={currentSlide.duration?currentSlide.duration[lang]:null}
                      year={currentSlide.year}
                      links={currentSlide.links}
                    />
                  </div>
                </>
              }
            </div>
          }
          {/* END Slide View */}
          
        </div>
        {/* END Timeline View */}
        <div className='gridCells hidden h-[100px] my-6 mt-8 md:flex w-full xl:w-[95%] self-center' />

      </div>
    </section>
  )
}
